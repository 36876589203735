import React, { Component } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/PageTitle"
export default class about extends Component {
  render() {
    return (
      <Layout>
        <Seo title={"About"} />
        <PageTitle pageTitle={"About"} />
        <div className="post-background">
          <div className="post-container">
            <div className="article">
              <h1>Welcome to the CodeParadox!</h1>
              <h2>Who am I?</h2>
              <p>
                I am Suraj Sharma, a computer science graduate from New Delhi,
                India. always eager to learn and explore new technologies.
              </p>
              <p>
                A blog started as a place to document everything I learned while
                go through my software engineer journey.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
